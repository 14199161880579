.sponsorlogo {
    height: 8em;
    width: 20em;
    border: 2px solid green;
}

.goldcontainer {
    display: flex;
    flex-wrap: wrap;
}

.sponsor {
    flex: 1 0 50%;
    /* 
      flex: 1 0 50% means:
      - flex-grow: 1 (each box takes equal space)
      - flex-shrink: 0 (boxes won't shrink)
      - flex-basis: 50% (initial size of each box, half of the container)
    */
    box-sizing: border-box;
    padding-bottom: 20px;
  }

.sponsorlabel {
    padding-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2em;
    color: black;
}

.mcclabel {
  /* padding-top: 50px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  text-align: justify;
  padding: 10px;
  color: black;
}

@media screen and (min-width: 768px) {
    .sponsor {
      flex-basis: 25%; /* 1/4 of the container for larger screens */
    }
  }