.image-thumbnail {
    display: inline-block;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    padding: 20px;
    border-radius: 5px;
  }
  
  .popup-content img {
    width: 70%;
    height:70%;
    border: 2px solid green;
  }

  @media (max-width: 768px) {
    .popup-content img {
        width: 80%;
        height:70%;
        border: 2px solid green;
      }
}
  .popup-content img button img {
    border: none;
  }
  .popup img {
    max-width: 100%;
    /* max-height: 80vh; */
  }
  
  .popup button {
    margin-top: 10px;
  }
  
  .close-hamburger {
    border: none;
    background: none;
    padding: 0px;
  }

  .x-hamburger {
    border: none !important;
    background: none;
    padding: 0px;
  }

