.menu-bar {
    background-color: #333;
    color: #fff;
    position: relative;

    display: flex;
    justify-content: center;
    vertical-align: middle;
    background-color: rgb(92, 175, 54);
    height: 50px;
    /* height: 500px; */
}

.menu {
    position: absolute;
    display: flex;
    list-style-type: none;
    /* padding: 0; */
    margin: 0;
}

.menu-item {
    /* position: relative; */
    padding: 10px 20px 10px 20px;
    /* padding: 10px 40px; */
    /* padding: 0px 40px 0px 40px; */
    top: 0;
    margin: 0;
    display: block;
    /* background-color: tan; */
    border-radius: 10px;

    /* background-color: yellow; */
    /* width: 30%; */
    /* border: 1px solid red; */


}

.menu-item a {
    text-decoration: none;
    padding: 10px 0px 0px 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
    color: white;
}

/* .menu-item a:hover {
    border: 1px solid red;
} */

.with-submenu {
    width: 90px;
    margin: 0;
    left: 0;
    top: 0;
    text-decoration: none;
    padding: 10px 0px 0px 0px;
    cursor: pointer;
}
.with-submenu .submenu li:hover {
    /* background-color: yellowgreen; */
    font-weight: bold;
    display: inline-block;
}

.with-submenu .submenu li {
    text-wrap: nowrap;
}

.menu-item:hover {
    /* background-color: rgb(92, 175, 54); */
    /* background-color: red; */
    border-radius: 10px;
    font-weight: bold;
}



.menu-item:hover .submenu {

    /* position: relative; */
    display: inline-block;
    top: 0;
    margin: 0;
    left: 0;
    background-color: rgb(92, 175, 54);
    border-radius: 10px;
}

/* .menu-item:hover .submenu {
    display: block;
    border: 5px solid red;
    position: relative;

  } */

.submenu {
    display: none;
    margin: 0;
    /* top: 100%; */
    left: 0;
    background-color: #444;
    list-style-type: none;
    padding: 0;
    z-index: 99999;
    /* position: relative; */
    /* position: absolute;
    
    
    
     */
}

.submenu li {
    padding: 5px;
    border-radius: 5px;
}

.submenu li a {
    text-decoration: none;
    padding: 5px;
}

.open-menu-btn {
    display: none;
}

@media (max-width: 768px) {
    .menu-bar {
        height: 10px;
        display: none;
    }
    .menu {
        flex-direction: column;
        left: 0;
        /* height: 30vh; */
        background-color: lightgreen;
        z-index: 99999;
        position: absolute;
    }

    .menu-item {
        position: static;
    }

    .with-submenu:hover .submenu {
        display: block;
        position: static;
    }

    .open-menu-btn {
        display: block;
    }

    .open {
        display: block;
    }

    .close {
        display: none;
    }
}