.teamHeader {
    text-align: center;
    color: sienna;
    padding-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
}

.teamSubheader {
    text-align: center;
    color: sienna;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 10px;
}

.teamContainer {
    font-family: Arial, Helvetica, sans-serif;
}

.member {
    display: block;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-wrap: balance;
    color: black;
}

.member-column {
    border: 1px solid lightgray;
    align-items: center;
    display: grid;
    justify-content: center;
}

.member-photo {
    width:85px;
    height: 125px;
    padding: 5px;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .member-photo {
        width:200px;
        height: 200px;
        border-radius: 20px;
    }
}
