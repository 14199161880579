
.sponsorcontainer {
    align-items: center;
    display: flex;
    left: 50%;
    position: relative; 
    transform: translate(-50%, 0%);
    color: sienna;
    background-color: white;
}

.sponsorLabel {
    text-wrap: nowrap;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
    text-align: right;
    color: black;
}

.ribbon-container {
    /* left: 600px; */
    overflow: hidden; /* Ensure content does not overflow */
     position: relative; /*Positioning context for absolute positioning of ribbon */
    width: 100%; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    background-color: white;
  }
  
  @keyframes scroll {
    0% { transform: translateX(0%); opacity: 1;} /* Start position */
    0%,90% {opacity: 1;}
    100% { transform: translateX(-125%); opacity: 0;} /* End position */
  }

  .ribbon-content {
    display: inline-block; /* Display content in a line */
    padding: 0 20px; 
  }

  .ribbon {
    display: flex;
    height: 100px;
    white-space: nowrap; 
    position: absolute; 
    animation: scroll 20s linear infinite;
  }

  @media (max-width: 768px) {
    .ribbon {
      display: flex;
      height: 100px;
      white-space: nowrap;
      position: absolute; 
      animation: scroll 20s linear infinite;
    }

    @keyframes scroll {
      0% { transform: translateX(0); opacity: 1;} 
      100% { transform: translateX(-300%); opacity: 1;} 
    }

    .sponsorLabel {
      text-wrap: nowrap;
      font-family: Arial, Helvetica, sans-serif;
      padding: 20px;
      text-align: right;
      color: black;
  }
  }