.home {
    background-image: url('WTA_Bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: calc(100vh - 300px);
}

@media (max-width: 768px) {
    .home {
        height: calc(100vh - 330px);
    }
}