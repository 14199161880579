.footer1 {
    display: flex;
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background-color: rgb(92, 175, 54);
    flex-shrink: 0;
    font-family: Arial, Helvetica, sans-serif;
    
    /* display: inline-block; */
    /* padding: 5px; */
    bottom: 0;
    position: fixed;
    left: 0;
    width: 100%;
}

.social-links {
    display: flex;
  }
  
  .social-links a {
    margin-right: 10px; /* Adjust as needed */
  }
  
  .social-links img {
    width: 30px; /* Adjust the size of icons */
    height: 30px; /* Adjust the size of icons */
  }
  
@media (max-width: 768px) {
    .social-links {
        display: flex;
      }
      
      .social-links a {
        margin-right: 10px; /* Adjust as needed */
      }
      
      .social-links img {
        width: 30px; /* Adjust the size of icons */
        height: 30px; /* Adjust the size of icons */
      }
      
}