.contact {
    min-height: 75vh;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
    color: black;
    text-align: left;
}

.contactWrapper {
    padding-top: 50px;
}