/* styles.css */

.tabs {
    display: flex;
  }
  
  .tab {
    padding: 10px;
    border: 1px solid black;

    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: white;
  }
  
  .tab.active {
    font-weight: bold;
    border: 1px solid black;
    border-bottom: none;
    background-color: rgb(92, 175, 54);
    color: white;
  }
  
  .tab-content {
    display: flex;
    /* border: 1px solid black; */
    max-height: 100%;
    margin-right: 5px;
    flex-direction: column;
    display: flow;
    background-color: rgb(92, 175, 54);
  }
  
  .tab-content img {
    width: 100px;
    height: 100px;
    margin: 10px;
  }
  