/* Menu.css */
.menu-container-mini {
    display: none;
  }
  
  .menu-button-mini {
    display: none;
  }
  
  .menu-mini {
    display: none;
  }
  
  .sub-menu-mini {
    display: none;
  }
  
  @media only screen and (max-width: 768px) {
    .menu-container-mini {
      display: block;
      background-color: rgb(92, 175, 54);
    }
  
    .menu-button-mini {
      display: block;
      background-color: rgb(92, 175, 54);
      color: white;
      border: none;

    }
  
    .menu-mini {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 200px;
      height: 100%;
      padding: 20px;
      overflow-y: auto;
      z-index: 1000;
      color: #fff;
      background-color: rgb(92, 175, 54);
    }
  
    .menu-mini ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    .menu-mini li {
      cursor: pointer;
      padding: 10px 0;
      border-bottom: 1px solid #555;
      text-align: left;
      padding-left: 5px;
    }
  
    li a {
        text-decoration: none;
        color: white;
    }

    .sub-menu-mini li a {
        padding-left: 20px;
        text-decoration: none;
    }

    .menu-mini li:hover {
      /* background-color: #555; */
      background-color: rgb(92, 175, 54);;
    }
  
    .sub-menu-mini {
      display: block;
      margin-top: 10px;
    }
  
    .close-menu-button-mini {
      margin-top: 20px;
      /* background-color: #555; */
      background-color: rgb(92, 175, 54);
      border: 1px solid black;
      
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
  
    .close-menu-button-mini:hover {
      background-color: #777;
    }

    .exp:before {
        display: inline-block;        
        content: '▼';
        padding-left: 10px;
    }

    .col:before {
        margin-right: 5px;
        display: inline-block;        
        content: '▶';
    }

    .hamClose {
      background-color: rgb(92, 175, 54);
      float: right;
    }

    .hamburger-close {
      background-color: rgb(92, 175, 54);
      border: none;
      padding-right: 0px;
      padding-top: 0px;
    }
  }
  