.ugadi-event-wrapper {
    padding-top: 50px;
    padding-left: 50px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

@media (max-width: 768px) {
    .ugadi-event-wrapper {
        padding-left: 5px;
        display: grid;
        text-align: justify;
        width: 95%;
    
    }
}