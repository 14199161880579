.headerlogo {
    height: 100px;
    border-radius: 50%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    background-color: rgb(92, 175, 54);
    font-family: Arial, Helvetica, sans-serif;
}

.wtaheader {
    padding: 0 0 0 20px;
    color: white;
}

